<template>
  <div class="home-container">
    <header data-role="Header" class="home-header">
      <img
        alt="logo"
        src="https://ik.imagekit.io/parasrenewables/logo-flat.svg?updatedAt=1705305907783"
        loading="eager"
        class="home-image"
      />
      <div class="home-nav"></div>
      <div class="home-btn-group">
        <a href="#footer" class="home-register button">Contact Us</a>
      </div>
    </header>
    <div class="home-hero">
      <h1 class="home-text">
        <span>Go Solar ☀️, </span>
        <span>Go Green 🍀</span>
      </h1>
      <span class="home-text03">
        <span class="home-text04">
          All energy is ultimately derived for the sun and harvesting it directly
          through solar power seems to be the best way to transition to renewable
          energy
        </span>
        <br />
      </span>
      <div class="home-btn-group1">
        <a href="#footer" class="home-link button">GET QUOTE</a>
      </div>
    </div>
    <div class="home-features">
      <div class="featuresContainer">
        <div class="home-features1">
          <div class="home-container1">
            <span class="overline">
              <span>features</span>
              <br />
            </span>
            <h2 class="home-features-heading heading2">Why Choose Us?</h2>
            <span class="home-features-sub-heading bodyLarge">
              <span>
                <span>
                  <span>
                    Discover the benefits of our solar panel installation services
                  </span>
                  <span><span v-html="rawbgj0"></span></span>
                </span>
                <span>
                  <span><span v-html="rawk4ig"></span></span>
                  <span><span v-html="rawsrug"></span></span>
                </span>
              </span>
              <span>
                <span>
                  <span><span v-html="raw6m5q"></span></span>
                  <span><span v-html="rawr5wz"></span></span>
                </span>
                <span>
                  <span><span v-html="rawrqw8"></span></span>
                  <span><span v-html="raw1t3f"></span></span>
                </span>
              </span>
            </span>
          </div>
          <div class="home-container2">
            <feature-card
              Heading="Customized Solar Solutions"
              SubHeading="Tailored solar panel installations to meet your specific energy needs"
            ></feature-card>
            <feature-card
              Heading="High-Quality Solar Panels"
              SubHeading="Premium solar panels that are durable and efficient"
            ></feature-card>
            <feature-card
              Heading="Expert Installation Team"
              SubHeading="Experienced professionals who ensure seamless installation and optimal performance"
            ></feature-card>
            <feature-card
              Heading="Energy Savings and Environmental Benefits"
              SubHeading="Reduce your electricity bills and carbon footprint with clean, renewable energy"
            ></feature-card>
          </div>
        </div>
      </div>
    </div>
    <div class="home-pricing"></div>
    <div class="home-banner"></div>
    <div class="home-faq">
      <div class="faqContainer">
        <div class="home-faq1">
          <div class="home-container3">
            <span class="overline">
              <span>FAQ</span>
              <br />
            </span>
            <h2 class="home-text26 heading2">Common questions</h2>
            <span class="home-text27 bodyLarge">
              <span>Here are some of the most common questions that we get.</span>
              <br />
            </span>
          </div>
          <div class="home-container4">
            <app-question1
              Answer="Solar panels can significantly reduce your electricity bills, help you save money in the long run, and reduce your carbon footprint."
              Question="What are the benefits of installing solar panels?"
            ></app-question1>
            <app-question1
              Answer="Solar panels typically have a lifespan of 25-30 years, but many can continue to produce electricity even after that period."
              Question="How long do solar panels last?"
            ></app-question1>
            <app-question1
              Answer="Yes, there are various federal, state, and local incentives available that can help offset the cost of installing solar panels. These incentives include tax credits, grants, and rebates."
              Question="Are there any government incentives or rebates available for solar panel installation?"
              rootClassName="question1-root-class-name"
            ></app-question1>
            <app-question1
              Answer="While a south-facing roof is ideal for maximum solar energy production, solar panels can still be installed on roofs facing east or west with slightly reduced efficiency."
              Question="Do I need to have a south-facing roof for solar panel installation?"
            ></app-question1>
            <app-question1
              Answer="Solar panels require minimal maintenance. Regular cleaning to remove dirt and debris is recommended, and occasional inspections to ensure optimal performance are advised."
              Question="What maintenance is required for solar panels?"
            ></app-question1>
          </div>
        </div>
      </div>
    </div>
    <footer id="footer" class="home-footer">
      <div class="home-container5">
        <div class="home-logo">
          <img
            alt="logo"
            src="https://ik.imagekit.io/parasrenewables/logo-flat.svg?updatedAt=1705305908274"
            class="home-image1"
          />
          <span class="home-text30">Powered by the sun, owned by you.</span>
        </div>
        <div class="home-links-container">
          <div class="home-container6"></div>
          <div class="home-container7">
            <div class="home-contact">
              <span class="home-text31">Contact Us</span>
              <a
                href="mailto:contact@parasrenewables.com?subject="
                class="home-link1"
              >
                contact@parasrenewables.com
              </a>
              <a href="tel:+917701807087" class="home-link2">+91 77018 07087</a>
            </div>
            <div class="home-socials"></div>
          </div>
        </div>
      </div>
      <div class="home-separator"></div>
      <span class="home-text32">
        © 2024 Paras Renewables, All Rights Reserved.
      </span>
    </footer>
  </div>
</template>

<script>
import FeatureCard from '../components/feature-card'
import AppQuestion1 from '../components/question1'

export default {
  name: 'Home',
  components: {
    FeatureCard,
    AppQuestion1,
  },
  data() {
    return {
      rawbgj0: ' ',
      rawk4ig: ' ',
      rawsrug: ' ',
      raw6m5q: ' ',
      rawr5wz: ' ',
      rawrqw8: ' ',
      raw1t3f: ' ',
    }
  },
  metaInfo: {
    title: 'Paras Renewables',
    meta: [
      {
        name: 'description',
        content:
          'All energy is ultimately derived for the sun and harvesting it directly through solar power seems to be the best way to transition to renewable energy',
      },
      {
        property: 'og:title',
        content: 'Paras Renewables',
      },
      {
        property: 'og:description',
        content:
          'All energy is ultimately derived for the sun and harvesting it directly through solar power seems to be the best way to transition to renewable energy',
      },
      {
        property: 'og:image',
        content:
          'https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/f0466252-778b-408b-868c-b276054509fe/7e54b8de-a7c8-46f2-96ff-9853799d79ff?org_if_sml=1&force_format=original',
      },
    ],
  },
}
</script>

<style scoped>
.home-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.home-header {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
}
.home-image {
  height: 3rem;
  align-self: center;
}
.home-nav {
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
}
.home-btn-group {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-register {
  font-style: normal;
  font-family: "Lexend Deca";
  font-weight: 700;
  padding-top: var(--dl-space-space-unit);
  border-color: #4cdf96;
  border-width: 2px;
  padding-left: var(--dl-space-space-oneandhalfunits);
  border-radius: 16px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  padding-bottom: var(--dl-space-space-unit);
  text-transform: uppercase;
  text-decoration: none;
  background-color: transparent;
}
.home-hero {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  position: relative;
  min-height: 80vh;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  justify-content: center;
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.8) 100%),url('https://ik.imagekit.io/parasrenewables/cover.jpg?updatedAt=1705303559679');
}
.home-text {
  color: var(--dl-color-gray-white);
  font-size: 3rem;
}
.home-text03 {
  color: var(--dl-color-gray-white);
  width: 50%;
  font-size: 24px;
  margin-top: var(--dl-space-space-twounits);
  text-align: center;
  margin-bottom: var(--dl-space-space-twounits);
}
.home-btn-group1 {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.home-link {
  font-size: 1.5rem;
  font-style: normal;
  transition: 0.3s;
  font-weight: 700;
  padding-top: var(--dl-space-space-unit);
  border-width: 0px;
  padding-left: var(--dl-space-space-twounits);
  border-radius: 24px;
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-unit);
  text-transform: uppercase;
  text-decoration: none;
  background-color: rgb(76, 223, 150);
}
.home-link:hover {
  transform: scale(1.02);
}
.home-features {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-features1 {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-fourunits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fourunits);
  justify-content: flex-start;
}
.home-container1 {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.home-features-heading {
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-features-sub-heading {
  font-size: 18px;
  text-align: center;
}
.home-container2 {
  width: 100%;
  display: grid;
  grid-gap: var(--dl-space-space-oneandhalfunits);
  grid-template-columns: 1fr 1fr;
}
.home-pricing {
  flex: 0 0 auto;
  width: 100%;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-banner {
  flex: 0 0 auto;
  width: 100%;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-faq {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-faq1 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  padding-top: var(--dl-space-space-fourunits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-fourunits);
}
.home-container3 {
  display: flex;
  max-width: 35%;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-text26 {
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-text27 {
  text-align: left;
}
.home-container4 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
.home-footer {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
}
.home-container5 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.home-logo {
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  flex-direction: column;
}
.home-image1 {
  height: 2rem;
}
.home-text30 {
  margin-top: var(--dl-space-space-oneandhalfunits);
}
.home-links-container {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.home-container6 {
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-items: flex-start;
  margin-right: 10rem;
  flex-direction: row;
  justify-content: space-between;
}
.home-container7 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-contact {
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  justify-content: flex-start;
}
.home-text31 {
  font-weight: 700;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-link1 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.home-link2 {
  text-decoration: none;
}
.home-socials {
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-separator {
  width: 100%;
  height: 1px;
  margin-top: var(--dl-space-space-twounits);
  margin-bottom: var(--dl-space-space-twounits);
  background-color: var(--dl-color-gray-900);
}
.home-text32 {
  align-self: center;
}
@media(max-width: 1600px) {
  .home-hero {
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.8) 100%),url('https://ik.imagekit.io/parasrenewables/cover.jpg?updatedAt=1705303559679');
  }
  .home-text {
    color: var(--dl-color-gray-white);
    font-size: 3rem;
  }
}
@media(max-width: 1200px) {
  .home-text {
    color: var(--dl-color-gray-white);
    font-size: 3rem;
  }
}
@media(max-width: 991px) {
  .home-hero {
    flex-direction: column;
  }
  .home-text {
    text-align: center;
  }
  .home-text03 {
    width: 80%;
    text-align: center;
  }
  .home-text04 {
    text-align: center;
  }
  .home-features-sub-heading {
    text-align: center;
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
  }
  .home-text27 {
    text-align: center;
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
  }
  .home-footer {
    flex-direction: column;
  }
  .home-container6 {
    margin-right: var(--dl-space-space-fourunits);
  }
}
@media(max-width: 767px) {
  .home-header {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-nav {
    display: none;
  }
  .home-btn-group {
    display: none;
  }
  .home-hero {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-features1 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-features-sub-heading {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .home-container2 {
    grid-template-columns: 1fr;
  }
  .home-faq1 {
    flex-direction: column;
  }
  .home-container3 {
    max-width: 100%;
    align-self: center;
    align-items: center;
    justify-content: center;
  }
  .home-text27 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .home-footer {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-container5 {
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }
  .home-logo {
    align-items: center;
  }
  .home-image1 {
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .home-text30 {
    margin-top: var(--dl-space-space-twounits);
    text-align: center;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .home-links-container {
    width: 100%;
    align-items: flex-start;
    flex-direction: row;
    justify-content: center;
  }
  .home-container6 {
    margin-right: var(--dl-space-space-fourunits);
  }
}
@media(max-width: 479px) {
  .home-header {
    padding: var(--dl-space-space-unit);
    padding-right: 16px;
  }
  .home-btn-group {
    display: flex;
  }
  .home-hero {
    width: 100%;
    padding: var(--dl-space-space-twounits);
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .home-text {
    color: var(--dl-color-gray-white);
    width: auto;
  }
  .home-text03 {
    width: 90%;
  }
  .home-btn-group1 {
    flex-direction: column;
  }
  .home-features1 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-footer {
    padding: var(--dl-space-space-unit);
  }
  .home-container5 {
    align-items: center;
    flex-direction: column;
  }
  .home-image1 {
    margin-bottom: 0px;
  }
  .home-text30 {
    margin-top: var(--dl-space-space-twounits);
    margin-bottom: var(--dl-space-space-twounits);
  }
  .home-links-container {
    align-items: center;
    flex-direction: column;
  }
  .home-container6 {
    margin-right: 0px;
  }
  .home-container7 {
    margin-top: var(--dl-space-space-oneandhalfunits);
    align-items: flex-start;
    margin-bottom: 0px;
  }
  .home-contact {
    align-items: flex-start;
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-text32 {
    text-align: center;
  }
}
</style>
